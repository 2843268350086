import { useEffect } from "react";
import styled from "@emotion/styled";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { useDispatch, useSelector } from "react-redux";
import { setProjectCreateOpenPopupAction } from "../../../redux/slices/project/createOrEdit";
import Typography from "@mui/material/Typography";
import { getIconComponentByType } from "../../../utils/assets";
import Button from "../../Common/Button";
import ProjectCard from "../../Project/Card";
import ProjectList from "../../Project/List";
import ProjectCreateOrEdit from "../../Project/CreateOrEdit";
import { setMapViewAction } from "redux/slices/mapbox/mapSlice";

const BoxStyled = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  marginTop: 4,
  marginBottom: 4,
  display: "inline-flex",
  justifyContent: "space-between",
  margin: 4,
}));

const GridContainerStyled = styled(Grid)(({ theme }) => ({
  //justifyContent: 'space-between',
  alignItems: "center",
  padding: "0px 62px 0px 44px",
  [theme.breakpoints.down("sm")]: {
    padding: 0,
    justifyContent: "center",
  },
}));

const ProjectMainContent = () => {
  const createProjectPopupOpen = useSelector(
    (state) => state.projectCreateOrEdit.createOpenPopup
  );

  const projects = useSelector((state) => state.projectList.data);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setMapViewAction({ mapView: false }));
  }, [])

  const handleCreateNewProjectClick = () => {
    dispatch(setProjectCreateOpenPopupAction({ openPopup: true }));
  };

  const handleClose = () => {
    dispatch(setProjectCreateOpenPopupAction({ openPopup: false }));
  };

  const handleShowGridView = () => {};
  const projectCount = projects ? projects.length : 0;

  const getHeadersWithIcon = (title, onClick, showIcon = false) => {
    return (
      <GridContainerStyled container>
        <Grid item xs>
          <Typography style={{ fontSize: 20 }}>{title}</Typography>
        </Grid>
        <Grid item>
          {showIcon && getIconComponentByType("gridView")}
          {!showIcon && (
            <Button
              onClick={onClick}
              label="Create New Project"
              sx={{ minWidth: 173, padding: "8px 16px !important" }}
            />
          )}
        </Grid>
      </GridContainerStyled>
    );
  };

  return (
    <>
      <Box
        style={{ width: "100%", display: "flex", flexDirection: "column" }}
        py={2}
      >
        <BoxStyled>
          {getHeadersWithIcon("Recent Projects", handleCreateNewProjectClick)}
        </BoxStyled>
        <BoxStyled>
          <GridContainerStyled
            container
            spacing={4}
            justifyContent={projects?.length < 4 && "flex-start"}
          >
            {projects?.length > 0 && projects?.slice(0, 4).map((item, index) => (
              <Grid item key={index}>
                <ProjectCard project={item} />
              </Grid>
            ))}
          </GridContainerStyled>
        </BoxStyled>
        <BoxStyled sx={{ flexGrow: 1 }}>
          {getHeadersWithIcon(
            `All Projects(${projectCount || 0})`,
            handleShowGridView,
            true
          )}
        </BoxStyled>
        <BoxStyled
          sx={{
            padding: {
              xs: 0,
              sm: "0px 62px 0px 44px",
            },
          }}
        >
          <ProjectList cellContents={projects} />
        </BoxStyled>
      </Box>
      <ProjectCreateOrEdit
        open={createProjectPopupOpen}
        handleClose={handleClose}
      />
    </>
  );
};

export default ProjectMainContent;
