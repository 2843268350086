import { combineReducers } from "@reduxjs/toolkit";
import loginSlice from "./slices/basic/loginSlice";
import appbarSlice from "./slices/dashboard/appbarSlice";
import drawerState from "./slices/dashboard/drawerSlice";
import mapState from "./slices/mapbox/mapSlice";
import projectCreateOrEditState from "./slices/project/createOrEdit";
import projectListState from "./slices/project/list";
import selectedProjectState from "./slices/project/selected";
import projectDeleteState from "./slices/project/delete";
import flightListState from "./slices/flight/list";
import flightCreateState from "./slices/flight/create";
import flightUploadProgressState from "./slices/flight/upload";
import flightEdit from "./slices/flight/edit";
import flightDateUpdate from "./slices/flight/updateDate";
import flightDelete from "./slices/flight/delete";
import flightSelectedState from "./slices/flight/selected";
import flightUploadLayers from "./slices/layers/flightUploadLayers";
import commonLayers from "./slices/layers/index";
import seeListState from "./slices/see/list";
import rasterLayersSlice from "./slices/layers/rasterLayersSlice";
import geoJsonLayers from "./slices/layers/geoJsonLayersSlice";
import commonMaterial from "./slices/material/material";
import projectMaterialCreate from "./slices/material/projectMaterial/create";
import projectMaterialList from "./slices/material/projectMaterial/list";
import projectMaterialEdit from "./slices/material/projectMaterial/edit";
import projectMaterialDelete from "./slices/material/projectMaterial/delete";
import exportSlice from "./slices/export/index";
import designCreate from "./slices/design/create";
import designList from "./slices/design/list";
import designDeleteAndSave from "./slices/design/deleteAndSave";
import measurementCreate from "./slices/measurement/create";
import measurementList from "./slices/measurement/list";
import measurementEdit from "./slices/measurement/edit";
import measurementDelete from "./slices/measurement/delete";
import measurementProfiler from "./slices/measurement/profiler";
import smartTrackingCreate from "./slices/smartTracking/create";
import smartTrackingList from "./slices/smartTracking/list";
import smartTrackingDelete from "./slices/smartTracking/delete";
import smartTrackingEdit from "./slices/smartTracking/edit";
import signup from "./slices/basic/signupSlice";
import resetPassword from "./slices/basic/resetPasswordSlice";
import logoutSlice from "./slices/basic/LogoutSlice";
import reportSlice from "redux/slices/report";
import lineworkList from "redux/slices/linework/list";
import lineworkCreate from "redux/slices/linework/create";
import panoSlice from "redux/slices/Panorama/list";

const rootReducer = combineReducers({
  // basic
  signup: signup,
  resetPassword: resetPassword,

  login: loginSlice,
  appbar: appbarSlice,
  drawerState: drawerState,
  mapState: mapState,
  logout: logoutSlice,

  // Project
  projectCreateOrEdit: projectCreateOrEditState,
  projectList: projectListState,
  selectedProject: selectedProjectState,
  projectDelete: projectDeleteState,

  // Flight
  flightList: flightListState,
  flightCreate: flightCreateState,
  flightUpload: flightUploadProgressState,
  flightEdit: flightEdit,
  flightDateUpdate: flightDateUpdate,
  flightDelete: flightDelete,
  flightSelected: flightSelectedState,

  // Layers
  flightUploadLayers: flightUploadLayers,
  rasterLayers: rasterLayersSlice,
  geoJsonLayers: geoJsonLayers,
  commonLayers: commonLayers,

  // See
  seeList: seeListState,


  // Material
  commonMaterial: commonMaterial,
  projectMaterialCreate: projectMaterialCreate,
  projectMaterialList: projectMaterialList,
  projectMaterialEdit: projectMaterialEdit,
  projectMaterialDelete: projectMaterialDelete,

  // Export
  export: exportSlice,

  // Design
  designCreate: designCreate,
  designList: designList,
  designDeleteAndSave: designDeleteAndSave,

  // Measurement
  measurementCreate: measurementCreate,
  measurementList: measurementList,
  measurementEdit: measurementEdit,
  measurementDelete: measurementDelete,
  measurementProfiler: measurementProfiler,

  // Smart Tracking
  smartTrackingCreate: smartTrackingCreate,
  smartTrackingList: smartTrackingList,
  smartTrackingDelete: smartTrackingDelete,
  smartTrackingEdit: smartTrackingEdit,

//report
  reportData: reportSlice,

//linework
  lineworkList: lineworkList,
  lineworkCreate: lineworkCreate,

//Panorama
  pano: panoSlice
});

export default rootReducer;
