import axios from "axios";
import exifr from "exifr";

export async function createFileFromBlobURL(url){
  return await fetch(url).then(r => r.blob())
}

export async function getImageTagsHelper(image) {
  const {longitude, latitude} =  await exifr.gps(image)
  return {
    tags: await exifr.parse(image, ["ImageDescription", "CreateDate",]),
    longitude,
    latitude
  }
}