import { createSlice } from "@reduxjs/toolkit";

const mapSlice = createSlice({
  name: "mapState",
  initialState: {
    lng: "-106.81",
    lat: "53.69",
    zoom: "3.00",
    mapView: true, 
    is3D: false,
    isPano: false,
  },
  reducers: {
    setCesiumView(state, action) {
      state.is3D = action.payload;
      if (action.payload) {
        state.mapView = false;
        state.isPano = false;
      }
     if (!action.payload) {
        state.mapView = true;
        state.isPano = false;
      }
    },
    setMapStateAction(state, action) {
      Object.assign(state, action.payload);
    },
    setMapViewAction(state, action) {
      state.mapView = action.payload?.mapView;
      // if (action.payload?.mapView) {
      //   state.isPano = false;
      //   state.is3D = false;
      // }
      // if(state.isPano || state.is3D)
    },
    setPanoViewAction(state, action) {
      state.isPano = action.payload;
      if (action.payload) {
        state.mapView = false;
        state.is3D = false;
      }
      if(!action.payload && !state.mapView) {
        state.mapView = true;
    }
    },
  },
});

export const { setCesiumView, setMapStateAction, setMapViewAction, setPanoViewAction } = mapSlice.actions;
export default mapSlice.reducer;
