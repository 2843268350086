import React from "react";
import ReactPannellum, { getConfig } from "react-pannellum";
import { useSelector } from 'react-redux';
import panoramaImage from "assets/images/360.JPG";


const PanoramaComponent = () => {
  const panoImage = useSelector((state) => state.pano);
  const config = {
      // autoRotate: -2,
  };

  return (
    <div style={{ width: "100%", height: "100vh" }}>
      <ReactPannellum
          id="1"
          sceneId="firstScene"
          imageSource={panoImage?.file || panoramaImage}
          config={config}
          autoLoad
        />
    </div>
  );
};

export default PanoramaComponent;
