import {createSlice} from "@reduxjs/toolkit";

const rasterLayersSlice = createSlice({
  name: "rasterLayersState",
  initialState: {},
  reducers: {
    createRasterLayerAction() {
    },
    rearrangeRasterLayersAction() {
    },
    setRasterLayerData(state, action) {
      return {
        ...state,
        [action.payload.layerKey]: {...action.payload.layerData}
      }
    },
    updateLayersOrderAction: (state, action) => {
      const updatedLayers = action.payload;
      console.log('Updated Layers:', updatedLayers); 

      updatedLayers.forEach((layer) => {
        const { type, order, uuid } = layer?.data || {};
        const layerName = layer?.name;
    
        switch (type) {
          case 'rasterLayer':
            if (state.rasterLayers?.[layerName]) {
              state.rasterLayers[layerName].order = order;
            }
            break;
    
          case 'linework':
            const index = state.lineworkList?.data?.findIndex((l) => l.uuid === uuid);
            if (index !== -1) {
              state.lineworkList.data[index].order = order;
            }
            break;
    
          case 'wmsLayer':
            if (state.wmsLayers?.[layerName]) {
              state.wmsLayers[layerName].order = order;
            }
            break;
    
          default:
            console.warn(`Layer not found or type mismatch for: ${layerName || uuid}`);
        }
      });
    
      state.combinedLayers = updatedLayers; 
    },
    
    
    exchangeRasterLayerOrderAction(state, action) {
      return {
        ...state,
        [action.payload.sourceLayer]: {...state[action.payload.sourceLayer], order: state[action.payload.destinationLayer].order},
        [action.payload.destinationLayer]: {...state[action.payload.destinationLayer], order: state[action.payload.sourceLayer].order}
      }
    },
    updateRasterLayerTransparencyAction(state, action) {
      return {
        ...state,
        [action.payload.key]: {...state[action.payload.key], paint: {"raster-opacity": action.payload.opacity}}
      }
    },
    updateRasterLayerVisibilityAction(state, action) {
      return {
        ...state,
        [action.payload.key]: {...state[action.payload.key], layout: {visibility: action.payload.visibility}}
      }
    },

    resetCombinedLayers: (state) => {
      if (!state.rasterLayers) {
        state.rasterLayers = {};
      }
      state.rasterLayers.combinedLayers = [];
    },
    

      }
    })

export const {
  createRasterLayerAction,
  setRasterLayerData,
  rearrangeRasterLayersAction,
  exchangeRasterLayerOrderAction,
  updateRasterLayerTransparencyAction,
  updateRasterLayerVisibilityAction,
  updateLayersOrderAction,
  resetCombinedLayers 
} = rasterLayersSlice.actions

export default rasterLayersSlice.reducer