import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import {getIconComponentByTypeWithProps} from "../../utils/assets";
import DialogContent from "@mui/material/DialogContent";
import TextField from "../Common/TextField";
import Grid from "@mui/material/Grid";
import {DialogActions} from "@mui/material";
import LoadingButton from "../Common/LoadingButton";
import {useForm} from "../../hooks/useForm";
import {useDispatch, useSelector} from "react-redux";
import {createProjectAction, editProjectAction} from "../../redux/slices/project/createOrEdit";
import isNull from "lodash/isNull";
import {useEffect} from "react";
import {useHistory} from "react-router-dom";

const initialValues = {
  name: "",
  description: ""
}

const ProjectCreateOrEdit = ({handleClose, project=null, open=false}) => {

  useEffect(() => {
    return () => {
      resetForm()
    };
  }, []);


 const loading = useSelector(state => state.projectCreateOrEdit.loading)
 const editSelectedProjectId = useSelector(state => state.projectCreateOrEdit.editSelectedProjectId)
  const dispatch = useDispatch()
  const history = useHistory()

  const validate = (fieldValues = values) => {
    let temp = {...errors}
    if ('name' in fieldValues)
      temp.name = fieldValues.name ? "" : "This field is required."
    setErrors({
      ...temp
    })

    if (fieldValues === values)
      return Object.values(temp).every(x => x === "")
  }
  const {
    values,
    errors,
    setErrors,
    handleInputChange,
    resetForm
  } = useForm(!isNull(project) ? project : initialValues, validate)

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
  }

  const handleSubmit = (e) => {
   e.stopPropagation()
    if (validate()) {
      if(!isNull(project)) {
        dispatch(editProjectAction({id: project.id, payload:values}))
      } else {
        dispatch(createProjectAction({...values, history: history}))
      }
    }
    resetForm()
  }
  return (
    <Dialog
      open={!!project ? project.id === editSelectedProjectId : open}
      aria-labelledby='project-create-update-dialog'
      onClose={handleClose}
      fullWidth
      maxWidth="xs"
      onClick={handleClick}
    >
      <DialogTitle id='project-create-update-title'>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>
            {editSelectedProjectId ? 'Edit Project' : 'Create New Project'} 
          </Typography>
          <IconButton
            onClick={handleClose}
          >
            {getIconComponentByTypeWithProps('close')({style: {color: 'black'}})}
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container direction="column" spacing={2}>
          <Grid item xs>
            <TextField
              name="name"
              label="Project Name"
              required={true}
              fullWidth
              value={values.name}
              error={errors.name}
              onChange={handleInputChange}
              onClick={e => e.stopPropagation()}
            />
          </Grid>
          <Grid item xs>
            <TextField
              name="description"
              label="Description"
              multiline
              rows={4}
              fullWidth
              error={errors.description}
              value={values.description}
              onChange={handleInputChange}
              onClick={e => e.stopPropagation()}
            />
          </Grid>
        <Grid item xs>
            <TextField
              name="document"
              label="Project Documents Link"
              fullWidth
              value={values.document}
              error={errors.document}
              onChange={handleInputChange}
              onClick={e => e.stopPropagation()}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          label="Save"
          variant="contained"
          loading={loading}
          sx={{
            margin: '0 auto'
          }}
          onClick={handleSubmit}
        />
      </DialogActions>
    </Dialog>
  )
}

export default ProjectCreateOrEdit