import React, { useEffect, useRef } from 'react';
import { Viewer, Entity, Cartesian3, Color, Cesium3DTileset } from 'cesium';
import 'cesium/Build/Cesium/Widgets/widgets.css';

const CesiumMap = () => {
  const viewerRef = useRef(null);

  useEffect(() => {
    const viewer = new Viewer(viewerRef.current, {
      animation: false,
      timeline: false,
    });

    viewer.entities.add({
      name: "Example Location",
      position: Cartesian3.fromDegrees(-74.0707383, 40.7117244, 1500),
      point: {
        pixelSize: 10,
        color: Color.RED,
      },
    });

    const tileset = new Cesium3DTileset({
      url: 'http://localhost:3001/converted_tiles/tileset.json', 
    });

    viewer.scene.primitives.add(tileset);

    tileset.readyPromise.then(() => {
      viewer.zoomTo(tileset);
    }).catch((error) => {
      console.error('Failed to load tileset:', error);
    });

    return () => {
      if (viewer && !viewer.isDestroyed()) {
        viewer.destroy();
      }
    };
  }, []);

  return (
    <div
      ref={viewerRef}
      style={{ width: '100%', height: '100vh' }}
    />
  );
};

export default CesiumMap;
