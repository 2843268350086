import { createSlice } from "@reduxjs/toolkit";
import { setDeleteProjectMaterialIdAction } from "../material/projectMaterial/delete";

const designDeleteAndSave = createSlice({
  name: "designDeleteAndSave",
  initialState: {
    selectedId: null,
    loading: false,
  },
  reducers: {
    saveDesignFileAction(state) {
      return {
        ...state,
        loading: true,
      };
    },
    deleteDesignFileAction(state, action) {
      return {
        ...state,
        loading: true,
      };
    },
    setDeleteDesignIdAction(state, action) {
      return {
        ...state,
        selectedId: action.payload.id,
      };
    },
    deleteDesignFileSuccessAction(state, action) {
      return {
        selectedId: null,
        loading: false,
      };
    },
  },
});

export const {
  saveDesignFileAction,
  setDeleteDesignIdAction,
  deleteDesignFileAction,
  deleteDesignFileSuccessAction,
} = designDeleteAndSave.actions;
export default designDeleteAndSave.reducer;
