import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import DialogActions from "@mui/material/DialogActions";
import Button from "../Common/Button";
import Box from "@mui/material/Box";
import LoadingButton from "../Common/LoadingButton";

const DeleteDialog = ({ item, onClose, handleDelete, selectedId, loading }) => {
  // const dispatch = useDispatch()
  // const loading  = useSelector(state => state.flightDelete.loading)
  // const selectedId  = useSelector(state => state.flightDelete.selectedId)

  // const handleDelete = () => {
  //   dispatch(deleteFlightAction({id: item.id}))
  // }
  return (
    <Dialog
      open={item?.id === selectedId}
      onClose={onClose}
      fullWidth
      maxWidth="xs"
      aria-labelledby="delete-item-dialog"
    >
      <DialogContent>
        <Typography align="center">
          Are you sure want to delete {item?.name}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{
            margin: "0 auto",
            width: "40%",
          }}
        >
          <Button label="Cancel" onClick={onClose} />
          <LoadingButton
            label="Delete"
            loading={loading}
            onClick={handleDelete}
            color="error"
          />
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteDialog;
