import Box from "@mui/material/Box";
import { styled } from "@mui/material";
import AppBar from "../AppBar";
import AppDrawer from "../Drawer";

import Paper from "@mui/material/Paper";
import { Switch, Route } from "react-router-dom";
import MainContent from "./MainContent";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getCommonMaterialAction } from "../../redux/slices/material/material";

const RootStyle = styled("div")(({ theme }) => ({
  height: `100vh`,
  margin: 0,
  padding: 0,
}));

const AppBarStyle = styled(Box)(({ theme }) => ({
  height: "64px",
  [theme.breakpoints.down("sm")]: {
    height: "54px",
  },
}));

const BodyStyle = styled(Box)(({ theme }) => ({
  maxHeight: "calc(100% - 64px)",
  overflow: "hidden",
  [theme.breakpoints.down("sm")]: {
    maxHeight: "calc(100% - 54px)",
  },
}));

const MainContentStyled = styled(Box)(({ theme, mapview }) => ({
  width: "100%",
  backgroundColor: "#F2F2F2",
  position: "relative",
  boxShadow: "none",
  borderRadius: 0,
}));

export default function Layout({ children }) {
  const dispatch = useDispatch();

  const mapView = useSelector((state) => state.mapState.mapView);

  useEffect(() => {
    dispatch(getCommonMaterialAction());
  }, []);

  return (
    <RootStyle>
      <AppBarStyle display="flex" flex="1" justifyContent="space-around">
        <AppBar />
      </AppBarStyle>
      <BodyStyle display="flex" flex="1">
        <Box>
          <AppDrawer>{children}</AppDrawer>
        </Box>
        <MainContentStyled
          component={Paper}
          //overflow="auto"
          mapview={mapView?.toString()}
        >
          <MainContent />
        </MainContentStyled>
      </BodyStyle>
    </RootStyle>
  );
}
